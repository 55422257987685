Lyte.Component.register("crux-related-list-wrapper", {
_template:"<template tag-name=\"crux-related-list-wrapper\"> <lyte-event-listener event-name=\"relatedListRefresh\" on-fire=\"{{action('refreshRL')}}\"></lyte-event-listener> <template is=\"if\" value=\"{{cxPropRelatedListYield}}\"><template case=\"true\"> <lyte-yield yield-name=\"cxPropRelatedListYield\"></lyte-yield> </template><template case=\"false\"> <template items=\"{{cxPropRelatedLists}}\" item=\"relatedList\" index=\"index\" is=\"for\"><div class=\"cx_detail_rl_container\"> <template is=\"if\" value=\"{{expHandlers(relatedList.api_name,'===','Notes')}}\"><template case=\"true\"><div id=\"{{relatedList.api_name}}__{{relatedList.id}}\" class=\"rl_scroll_header\"> <crux-note cx-prop-entity=\"{{cxPropRecord}}\" cx-prop-module=\"{{cxPropModule}}\" relid=\"{{relatedList.id}}\" cx-prop-selected-filter=\"All\" cx-prop-note-count=\"true\" cx-prop-related-list=\"{{relatedList}}\"></crux-note> </div></template><template case=\"false\"> <crux-related-list-table cx-prop-bulk-request-to=\"{{cxPropBulkRequestTo}}\" cx-prop-module-id=\"{{cxPropModuleId}}\" id=\"rl_{{relatedList.id}}\" cx-prop-rl=\"{{relatedList}}\" cx-prop-rl-data=\"{{cxPropRlData[relatedList.id]}}\" cx-prop-module=\"{{cxPropModule}}\" cx-prop-entity-id=\"{{cxPropEntityId}}\" cx-prop-layout-id=\"{{cxPropLayoutId}}\" related-list-table-action=\"{{method('relatedListWrapperAction')}}\" rl-rec-action=\"{{method('rlRecAction')}}\"></crux-related-list-table> </template></template> </div></template> </template></template> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"componentDynamic","position":[1]},{"type":"attr","position":[3]},{"type":"if","position":[3],"cases":{"true":{"dynamicNodes":[{"type":"insertYield","position":[1]}]},"false":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"for","position":[1],"dynamicNodes":[{"type":"attr","position":[0,1]},{"type":"if","position":[0,1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"attr","position":[0,1]},{"type":"componentDynamic","position":[0,1]}]},"false":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"componentDynamic","position":[1]}]}},"default":{}}]}]}},"default":{}}],
_observedAttributes :["cxPropRelatedLists","cxPropModule","cxPropModuleId","cxPropLayoutId","cxPropEntityId","cxPropRlData","cxPropRecord","cxPropRelatedListYield","cxPropBulkRequestTo"],
	data : function(){
		return {
			cxPropRelatedLists	 : Lyte.attr('array'),
			cxPropModule 	  	 : Lyte.attr('string'),
			cxPropModuleId: Lyte.attr('string'),
			cxPropLayoutId		 : Lyte.attr('string'),
			cxPropEntityId		 : Lyte.attr('string'), 
			cxPropRlData 		 : Lyte.attr('object'),
			cxPropRecord 		 : Lyte.attr('object'),
			cxPropRelatedListYield : Lyte.attr('boolean'),
			cxPropBulkRequestTo : Lyte.attr('string')
		}		
	},
	init : function(){
		// if(!this.getData('cxPropRelatedLists')){
		// 	//find all related lists
		// }
	},
	observeLayoutId : function(){
		var _self = this;
		var rlList = this.getData('cxPropRelatedLists');
		if(!rlList){
			store.findAll('related_list',{module : this.getData('cxPropModule'), layout_id : this.getData('cxPropLayoutId')}).then(function(res){
				var list = res.filter(function (item) { return item.visible; });
				_self.setData('cxPropRelatedLists',list);
			})
		}
	}.observes('cxPropLayoutId'),
	actions : {
		// Functions for event handling
		refreshRL : function(data){
			var rlNode = this.$node.querySelector('#rl_'+data.id);
			rlNode.component.setData('cxPropRlData',data.rlData);
		}
	},
	methods : {
		// Functions which can be used as callback in the component.
		relatedListWrapperAction : function(actionId, rlId , elem , eventObj){
			if(this.getMethods('relatedListAction')){
				return this.executeMethod('relatedListAction',actionId, rlId , elem , eventObj);
			}
		},
		rlRecAction : function(actionId,recId,rlId,elem,eventObj,record){
			if(this.getMethods('rlRecAction')){
				return this.executeMethod('rlRecAction',actionId ,recId , rlId , elem , eventObj,record);
			}
		} 
	}
});
